@font-face {
  font-family: 'Visby CF';
  src: url('./font/VisbyCF-LightOblique.eot');
  src: local('Visby CF Light Oblique'), local('VisbyCF-LightOblique'),
    url('./font/VisbyCF-LightOblique.eot?#iefix') format('embedded-opentype'),
    url('./font/VisbyCF-LightOblique.woff2') format('woff2'),
    url('./font/VisbyCF-LightOblique.woff') format('woff'),
    url('./font/VisbyCF-LightOblique.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Visby CF';
  src: url('./font/VisbyCF-Bold.eot');
  src: local('Visby CF Bold'), local('VisbyCF-Bold'),
    url('./font/VisbyCF-Bold.eot?#iefix') format('embedded-opentype'),
    url('./font/VisbyCF-Bold.woff2') format('woff2'),
    url('./font/VisbyCF-Bold.woff') format('woff'),
    url('./font/VisbyCF-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Visby CF';
  src: url('./font/VisbyCF-Light.eot');
  src: local('Visby CF Light'), local('VisbyCF-Light'),
    url('./font/VisbyCF-Light.eot?#iefix') format('embedded-opentype'),
    url('./font/VisbyCF-Light.woff2') format('woff2'),
    url('./font/VisbyCF-Light.woff') format('woff'),
    url('./font/VisbyCF-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Visby CF Extra';
  src: url('./font/VisbyCF-ExtraBold.eot');
  src: local('Visby CF Extra Bold'), local('VisbyCF-ExtraBold'),
    url('./font/VisbyCF-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./font/VisbyCF-ExtraBold.woff2') format('woff2'),
    url('./font/VisbyCF-ExtraBold.woff') format('woff'),
    url('./font/VisbyCF-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Visby CF';
  src: url('./font/VisbyCF-BoldOblique.eot');
  src: local('Visby CF Bold Oblique'), local('VisbyCF-BoldOblique'),
    url('./font/VisbyCF-BoldOblique.eot?#iefix') format('embedded-opentype'),
    url('./font/VisbyCF-BoldOblique.woff2') format('woff2'),
    url('./font/VisbyCF-BoldOblique.woff') format('woff'),
    url('./font/VisbyCF-BoldOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Visby CF';
  src: url('./font/VisbyCF-ThinOblique.eot');
  src: local('Visby CF Thin Oblique'), local('VisbyCF-ThinOblique'),
    url('./font/VisbyCF-ThinOblique.eot?#iefix') format('embedded-opentype'),
    url('./font/VisbyCF-ThinOblique.woff2') format('woff2'),
    url('./font/VisbyCF-ThinOblique.woff') format('woff'),
    url('./font/VisbyCF-ThinOblique.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Visby CF';
  src: url('./font/VisbyCF-Heavy.eot');
  src: local('Visby CF Heavy'), local('VisbyCF-Heavy'),
    url('./font/VisbyCF-Heavy.eot?#iefix') format('embedded-opentype'),
    url('./font/VisbyCF-Heavy.woff2') format('woff2'),
    url('./font/VisbyCF-Heavy.woff') format('woff'),
    url('./font/VisbyCF-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Visby CF';
  src: url('./font/VisbyCF-Thin.eot');
  src: local('Visby CF Thin'), local('VisbyCF-Thin'),
    url('./font/VisbyCF-Thin.eot?#iefix') format('embedded-opentype'),
    url('./font/VisbyCF-Thin.woff2') format('woff2'),
    url('./font/VisbyCF-Thin.woff') format('woff'),
    url('./font/VisbyCF-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Visby CF';
  src: url('./font/VisbyCF-Medium.eot');
  src: local('Visby CF Medium'), local('VisbyCF-Medium'),
    url('./font/VisbyCF-Medium.eot?#iefix') format('embedded-opentype'),
    url('./font/VisbyCF-Medium.woff2') format('woff2'),
    url('./font/VisbyCF-Medium.woff') format('woff'),
    url('./font/VisbyCF-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Visby CF';
  src: url('./font/VisbyCF-ExtraBoldOblique.eot');
  src: local('Visby CF Extra Bold Oblique'), local('VisbyCF-ExtraBoldOblique'),
    url('./font/VisbyCF-ExtraBoldOblique.eot?#iefix') format('embedded-opentype'),
    url('./font/VisbyCF-ExtraBoldOblique.woff2') format('woff2'),
    url('./font/VisbyCF-ExtraBoldOblique.woff') format('woff'),
    url('./font/VisbyCF-ExtraBoldOblique.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Visby CF';
  src: url('./font/VisbyCF-MediumOblique.eot');
  src: local('Visby CF Medium Oblique'), local('VisbyCF-MediumOblique'),
    url('./font/VisbyCF-MediumOblique.eot?#iefix') format('embedded-opentype'),
    url('./font/VisbyCF-MediumOblique.woff2') format('woff2'),
    url('./font/VisbyCF-MediumOblique.woff') format('woff'),
    url('./font/VisbyCF-MediumOblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Visby CF';
  src: url('./font/VisbyCF-HeavyOblique.eot');
  src: local('Visby CF Heavy Oblique'), local('VisbyCF-HeavyOblique'),
    url('./font/VisbyCF-HeavyOblique.eot?#iefix') format('embedded-opentype'),
    url('./font/VisbyCF-HeavyOblique.woff2') format('woff2'),
    url('./font/VisbyCF-HeavyOblique.woff') format('woff'),
    url('./font/VisbyCF-HeavyOblique.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Visby CF Demi';
  src: url('./font/VisbyCF-DemiBold.eot');
  src: local('Visby CF Demi Bold'), local('VisbyCF-DemiBold'),
    url('./font/VisbyCF-DemiBold.eot?#iefix') format('embedded-opentype'),
    url('./font/VisbyCF-DemiBold.woff2') format('woff2'),
    url('./font/VisbyCF-DemiBold.woff') format('woff'),
    url('./font/VisbyCF-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Visby CF Demi Bold Oblique';
  src: url('./font/VisbyCF-DemiBoldOblique.eot');
  src: local('Visby CF Demi Bold Oblique'), local('VisbyCF-DemiBoldOblique'),
    url('./font/VisbyCF-DemiBoldOblique.eot?#iefix') format('embedded-opentype'),
    url('./font/VisbyCF-DemiBoldOblique.woff2') format('woff2'),
    url('./font/VisbyCF-DemiBoldOblique.woff') format('woff'),
    url('./font/VisbyCF-DemiBoldOblique.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
* {
  margin: 0;
  padding: 0;
  user-select: none;
}
*:focus {
  outline: none;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: #3c3b3f;
  border-radius: 5px;
}
.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
body {
  background: #f8f8f8 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dark {
  background: #2d2d2d !important;
}
.dark .tinchlikplaza_bron_menu {
  background: #414141;
}
.dark .tinchlikplaza_bron_navbar_swiper > span {
  background: #424242;
  border: 0.5px solid #d7d7d7;
  box-sizing: border-box;
  svg {
    path {
      stroke: #ffffff;
    }
  }
}
.dark
  .tinchlikplaza_bron_navbar_swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  > a {
  background: #424242;
  border: 0.5px solid #d7d7d7;
  color: #fcffff;
}
.dark .tinchlikplaza_bron_container > .qavatlar > div:nth-child(1) {
  background: #414141;
  border: 0.5px solid #c4c4c4;
  color: #fcffff;
}
.dark .tinchlikplaza_bron_container .band_footer > div > div:nth-child(2) > p {
  color: #fcffff;
}
.dark .tinchlikplaza_bron_menu .notification > div:nth-child(2) {
  background: #414141;
}
.dark
  .tinchlikplaza_bron_menu
  .notification
  > div:nth-child(2)
  > div:nth-child(1)
  > div {
  background: #424242;
}
.dark
  .tinchlikplaza_bron_menu
  .notification
  > div:nth-child(2)
  > div:nth-child(1)
  > div
  > div
  > div
  > h1 {
  color: #fcffff;
}
.dark .ant-modal-content {
  background: #424242;
}
.dark .ant-modal-body h3 {
  color: #fcffff !important;
}
.dark .ant-modal-body span {
  color: #fcffff !important;
}
.dark .ant-modal-body h1 {
  color: #fcffff !important;
}
.dark .ant-modal-header {
  background: #424242;
}
.dark .ant-modal-header > div {
  color: #fcffff !important;
}
.dark .ant-modal-title {
  color: #fcffff;
}
.dark .ant-modal-close-x > span > svg > path {
  fill: #fcffff;
}
.dark .ant-modal-body .ant-select-selector {
  background: transparent !important;
}
.dark
  .ant-modal-body
  > div:nth-child(2)
  > div
  .ant-select
  .ant-select-selection-item {
  color: #fcffff;
}
.dark .ant-modal-body .ant-select-arrow {
  background: #fcffff !important;
}
.dark
  .tinchlikplaza_bron_navbar_swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  > a.active {
  border: 1px solid #ffc593;
  color: #ffc593;
  filter: drop-shadow(0px 38px 68px rgba(255, 197, 147, 0.1))
    drop-shadow(0px 15.8755px 28.4088px rgba(255, 197, 147, 0.0718854))
    drop-shadow(0px 8.4878px 15.1887px rgba(255, 197, 147, 0.0596107))
    drop-shadow(0px 4.75819px 8.51466px rgba(255, 197, 147, 0.05))
    drop-shadow(0px 2.52704px 4.52207px rgba(255, 197, 147, 0.0403893))
    drop-shadow(0px 1.05156px 1.88173px rgba(255, 197, 147, 0.0281146));
  &::after {
    background: #ffc593;
  }
}
.dark .ant-dropdown .ant-dropdown-menu {
  background: #424242 !important;
}
.dark .ant-dropdown-menu-item:hover,
.dark .ant-dropdown-menu-submenu-title:hover {
  background: #424242 !important;
}
.dark .ant-dropdown-menu {
  background: #424242 !important;
}
.dark .ant-dropdown-menu span {
  color: #fcffff !important;
}
.dark .ant-tag-geekblue {
  background: #424242 !important;
}
.dark .ant-tag.site-tag-plus {
  background: #424242 !important;
}
.dark .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content {
  color: #fcffff !important;
}
