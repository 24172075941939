.tinchlikplaza_bron_navbar {
  &_swiper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 38px;
    box-sizing: border-box;
    > span {
      background: #ffffff;
      border: 0.5px solid #d7d7d7;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      width: 28px;
      height: 28px;
    }
    .swiper-container {
      width: 92%;
      .swiper-wrapper {
        .swiper-slide {
          width: 60px;
          > a.active {
            border: 1px solid #562cdd;
            &::after {
              content: '';
              position: absolute;
              background: #562cdd;
              width: 3px;
              bottom: 10px;
              height: 3px;
              border-radius: 50%;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          > a {
            height: 55px;
            background: #ffffff;
            display: flex;
            flex-direction: column;
            flex-flow: column-reverse;
            position: relative;
            justify-content: center;
            align-items: center;
            border: 0.5px solid #d7d7d7;
            box-sizing: border-box;
            border-radius: 5px;
            cursor: pointer;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 12px;
            text-align: center;
            color: #363636;
            > span:nth-child(2) {
              font-size: 10px;
              margin-top: -10px;
              font-weight: 200;
              color: #838383;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
