@media (min-height: 700px) {
  .tinchlikplaza_bron_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: calc(100vh - 170px);
    margin-top: 18px;
  }
}
.tinchlikplaza_bron_container {
  margin-top: 18px;
  > .qavatlar {
    display: flex;
    align-items: center;
    margin: 16px 0;
    > div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border: 0.5px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 0px 50px 50px 0px;
      height: 44px;
      width: 88px;
      margin-right: 14px;
    }
    > div:nth-child(2) {
      width: calc(100% - 102px);
      //   overflow-x: auto;
      flex-wrap: wrap;
      //   overflow-y: hidden;
      display: flex;
      > div[status='BUSY'] {
        background: #8b141a;
        > div {
          background: #dc333c;
          color: white;
        }
      }
      > div {
        width: 40px;
        min-width: 40px;
        height: 70px;
        background: #044527;
        border-radius: 5px;
        margin-right: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.5s;
        > span {
          display: flex;
          height: 18px;
          justify-content: center;
          align-items: center;
          font-family: Visby CF;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #fdfeff;
        }
        > div {
          background: #38c257;
          border-radius: 5px;
          height: 51px;
          text-align: center;
          display: flex;
          width: 100%;
          align-items: flex-end;
          justify-content: center;
          padding-bottom: 3px;
          box-sizing: border-box;
        }
      }
    }
  }
  .band_footer {
    display: flex;
    margin-left: 100px;
    padding-bottom: 9px;
    > div {
      display: flex;
      margin-right: 35px;
      > div:nth-child(1) {
        margin-right: 12px;
        background: #8b141a;
        border-radius: 5px;
        height: 31px;
        width: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        > div {
          background: #dc333c;
          border-radius: 5px;
          width: 100%;
          height: 21px;
        }
      }
      > div:nth-child(2) {
        > p {
          font-family: Visby CF;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: #626262;
          margin-bottom: 0;
        }
      }
    }
    > div:nth-child(2) {
      > div:nth-child(1) {
        background: #044527;
      }
      > div:nth-child(1) {
        > div {
          background: #38c257;
        }
      }
    }
  }
}
.xona_modal {
  .ant-picker-calendar-mini .ant-picker-panel {
    pointer-events: none;
  }
  // .ant-modal-footer {
  //   .ant-btn.ant-btn-primary {
  //     display: none;
  //   }
  // }
  // .ant-modal-title {
  //   font-family: Visby CF;
  //   font-style: normal;
  //   font-weight: normal;
  //   font-size: 20px;
  //   line-height: 24px;
  //   color: #3b037a;
  // }
  // .ant-modal-content {
  //   border-radius: 7px;
  //   position: relative;
  //   overflow: hidden;
  // }
  // .ant-modal-body {
  //   padding-bottom: 0;

  //   > div:nth-child(1) {
  //     > div {
  //       display: flex;
  //       width: 100%;
  //       justify-content: space-between;
  //       margin-bottom: 10px;
  //       > div {
  //         > p {
  //           font-family: Visby CF;
  //           font-style: normal;
  //           font-weight: normal;
  //           font-size: 14px;
  //           line-height: 17px;
  //           color: #626262;
  //           margin-bottom: 0;
  //         }
  //         > h3 {
  //           font-family: Visby CF;
  //           font-style: normal;
  //           font-weight: 600;
  //           font-size: 16px;
  //           line-height: 19px;
  //           color: #3b037a;
  //         }
  //       }
  //     }
  //   }
  //   > div:nth-child(2) {
  //     border-top: 0.5px solid #b8b8b8;
  //     padding-bottom: 10px;
  //     display: flex;
  //     justify-content: space-between;
  //     padding-right: 50px;
  //     box-sizing: border-box;
  //     > div {
  //       > p {
  //         font-family: Visby CF;
  //         font-style: normal;
  //         font-weight: normal;
  //         font-size: 14px;
  //         line-height: 17px;
  //         margin: 5px 0;
  //         color: #626262;
  //         > span {
  //           font-family: Visby CF;
  //           font-style: normal;
  //           font-weight: 600;
  //           font-size: 16px;
  //           line-height: 19px;
  //           color: #3b037a;
  //         }
  //       }
  //       > h1 {
  //         font-family: Visby CF;
  //         font-style: normal;
  //         font-weight: 600;
  //         font-size: 16px;
  //         line-height: 19px;
  //         margin-bottom: 0;
  //         color: #3b037a;
  //       }
  //     }
  //   }
  // }
}
.bushatish_modal {
  .ant-modal-title {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #3b037a;
  }
  .ant-modal-footer {
    .ant-btn.ant-btn-primary {
      background: #3b037a;
      border: 0.5px solid #d7d7d7;
      box-sizing: border-box;
      border-radius: 5px;
    }
  }
  .ant-modal-body {
    > div {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 15px;
      border-bottom: 0.5px solid #b8b8b8;
    }
    > p {
      margin-top: 13px;
      font-family: Visby CF;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #626262;
      margin-bottom: 0;
    }
    > h1 {
      font-family: Visby CF;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #3b037a;
      margin-bottom: 0;
    }
  }
}
.kuchirish_modal {
  .ant-modal-footer {
    .ant-btn.ant-btn-primary {
      background: #3b037a;
      border: 0.5px solid #d7d7d7;
      box-sizing: border-box;
      border-radius: 5px;
    }
  }
  .ant-modal-title {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #3b037a;
  }
  .ant-modal-content {
    border-radius: 7px;
    position: relative;
    overflow: hidden;
  }
  .ant-modal-body {
    > p {
      font-family: Visby CF;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #626262;
      padding-top: 13px;
      margin-bottom: 0;
    }
    > h1 {
      font-family: Visby CF;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #3b037a;
      margin-bottom: 0;
    }
    div {
      display: flex;
      justify-content: space-between;
    }
    .ant-select {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background: #ffffff;
        border: 0.5px solid #d7d7d7;
        box-sizing: border-box;
        border-radius: 5px;
      }
      .ant-select-selection-item {
        font-family: Visby CF;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #3b037a;
      }
      .ant-select-arrow {
        transform: translateY(30%);
        background: #3b037a;
        clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
        svg {
          display: none;
        }
      }
    }
  }
}
