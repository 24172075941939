.tinchlikplaza_bron_menu {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  background: #ffffff;
  > img {
    width: 40px;
    height: 40px;
    margin-left: 18px;
  }
  > div {
    > button {
      background: transparent;
      border: none;
      cursor: pointer;
      margin-right: 24px;
      > img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .notification {
    width: 100%;
    height: calc(100% - 60px);
    position: fixed;
    top: 60px;
    display: none;
    left: 0;
    z-index: 10;
    > div:nth-child(1) {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
      left: 0;
      background: rgba(34, 34, 34, 0.1);
      backdrop-filter: blur(8px);
      opacity: 0;
      transition: 0.2s;
    }
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 6px 0 8px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%) translateX(100%);
      height: 98%;
      width: 302px;
      background: #ffffff;
      border: 0.5px solid #d7d7d7;
      box-sizing: border-box;
      box-shadow: 0px 33px 150px rgba(0, 0, 0, 0.03),
        0px 12.0455px 54.7525px rgba(0, 0, 0, 0.0206994),
        0px 5.84789px 26.5813px rgba(0, 0, 0, 0.0166887),
        0px 2.86674px 13.0307px rgba(0, 0, 0, 0.0133113),
        0px 1.13351px 5.15234px rgba(0, 0, 0, 0.00930055);
      border-radius: 5px;
      > div:nth-child(1) {
        position: relative;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 3px;
          border: 1px solid white;
        }
        > main.active {
          display: block;
        }
        > main {
          display: none;
        }
        > .main2 {
          display: none;
        }
        > .main2.active {
          display: block;
        }
        > .main2 > div,
        > .main3 > div,
        > main > div {
          position: relative;
          padding: 8px 10px;
          box-sizing: border-box;
          background: #ffffff;
          transition: 0.3s;
          cursor: pointer;
          border: 1px solid #d7d7d7;
          box-shadow: 0px 33px 150px rgba(0, 0, 0, 0.03),
            0px 12.0455px 54.7525px rgba(0, 0, 0, 0.0206994),
            0px 5.84789px 26.5813px rgba(0, 0, 0, 0.0166887),
            0px 2.86674px 13.0307px rgba(0, 0, 0, 0.0133113),
            0px 1.13351px 5.15234px rgba(0, 0, 0, 0.00930055);
          border-radius: 3px;
          width: 290px;
          height: 101px;
          margin-bottom: 6px;
          &:hover {
            border: 1px solid #3b037a;
          }
          > h3 {
            font-family: Visby CF;
            font-style: normal;
            font-weight: 500;
            font-size: 8px;
            line-height: 10px;
            color: #838383;
          }
          .apartament_close {
            position: absolute;
            width: 12px;
            height: 12px;
            top: 3px;
            right: 3px;
            cursor: pointer;
          }
          > div {
            display: flex;
            justify-content: space-between;
            > div {
              > h1 {
                font-family: Visby CF;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: #424242;
              }
              > p {
                font-family: Visby CF;
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 12px;
                color: #838383;
                margin-bottom: 0;
              }
            }
          }
        }
      }
      > div:nth-child(2) {
        display: flex;
        margin: 10px 0;
        margin-top: auto;
        > button {
          margin: 0 10px;
          border: 1px solid blue;
          color: blue;
          border: none;
          cursor: pointer;
          width: 30px;
          position: relative;
        }
      }
      > div:nth-child(3) {
        display: flex;
        width: 100%;
        padding: 0 6px;
        justify-content: space-between;
        box-sizing: border-box;
        > span {
          background: #dc333c;
          border: 0.5px solid #d7d7d7;
          box-sizing: border-box;
          box-shadow: 0px 33px 150px rgba(0, 0, 0, 0.03),
            0px 12.0455px 54.7525px rgba(0, 0, 0, 0.0206994),
            0px 5.84789px 26.5813px rgba(0, 0, 0, 0.0166887),
            0px 2.86674px 13.0307px rgba(0, 0, 0, 0.0133113),
            0px 1.13351px 5.15234px rgba(0, 0, 0, 0.00930055);
          border-radius: 3px;
          width: 49px;
          height: 32px;
          font-family: Visby CF;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
        }
        > button {
          width: 235px;
          height: 32px;
          background: #ffffff;
          border: 0.5px solid #d7d7d7;
          box-sizing: border-box;
          box-shadow: 0px 33px 150px rgba(0, 0, 0, 0.03),
            0px 12.0455px 54.7525px rgba(0, 0, 0, 0.0206994),
            0px 5.84789px 26.5813px rgba(0, 0, 0, 0.0166887),
            0px 2.86674px 13.0307px rgba(0, 0, 0, 0.0133113),
            0px 1.13351px 5.15234px rgba(0, 0, 0, 0.00930055);
          border-radius: 3px;
          font-family: Visby CF;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: 0.3s;
          > svg {
            margin-right: 12px;
            transition: 1s;
            pointer-events: none;
          }
        }
        > button.active {
          background: rgb(88, 121, 190);
          color: white;
          > svg {
            transform: rotateZ(720deg);
          }
        }
      }
    }
  }
}
.notification_modal {
  .ant-modal-footer {
    .ant-btn.ant-btn-primary {
      background: #3b037a;
      border: 0.5px solid #d7d7d7;
      box-sizing: border-box;
      border-radius: 5px;
    }
  }
  .ant-modal-title {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #3b037a;
  }
  .ant-modal-content {
    border-radius: 7px;
    position: relative;
    overflow: hidden;
  }
  .ant-modal-body {
    padding-bottom: 0;
    > div:nth-child(1) {
      > div {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 10px;
        > div {
          > p {
            font-family: Visby CF;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #626262;
            margin-bottom: 0;
          }
          > h3 {
            font-family: Visby CF;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #3b037a;
          }
        }
      }
    }
    > div:nth-child(2) {
      border-top: 0.5px solid #b8b8b8;
      padding-bottom: 10px;
      display: flex;
      justify-content: space-between;
      padding-right: 50px;
      box-sizing: border-box;
      > div {
        > p {
          font-family: Visby CF;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          margin: 5px 0;
          color: #626262;
          > span {
            font-family: Visby CF;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #3b037a;
          }
        }
        > h1 {
          font-family: Visby CF;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 0;
          color: #3b037a;
        }
        .ant-select {
          margin-top: 5px;
          margin-right: 10px;
          .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background: #ffffff;
            border: 0.5px solid #d7d7d7;

            box-sizing: border-box;
            border-radius: 5px;
          }
          .ant-select-selection-item {
            font-family: Visby CF;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #3b037a;
          }
          .ant-select-arrow {
            transform: translateY(30%);
            background: #3b037a;
            clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
            svg {
              display: none;
            }
          }
        }
      }
    }
    > div:nth-child(3) {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
      > button:nth-child(1) {
        background: #ccc;
        color: #f00;
        margin-right: 10px;
      }
      > button {
        cursor: pointer;
        background: #3b037a;
        border: 0.5px solid #d7d7d7;
        box-sizing: border-box;
        border-radius: 5px;
        color: white;
        padding: 3px 10px;
      }
    }
  }
}
.notificationSoket {
  position: relative;
  padding: 8px 10px;
  box-sizing: border-box;
  background: #ffffff;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 33px 150px rgb(0 0 0 / 3%), 0px 12.0455px 54.7525px rgb(0 0 0 / 2%),
    0px 5.84789px 26.5813px rgb(0 0 0 / 2%), 0px 2.86674px 13.0307px rgb(0 0 0 / 1%),
    0px 1.13351px 5.15234px rgb(0 0 0 / 1%);
  border-radius: 3px;
  height: 101px;
  margin-bottom: 6px;
  > h3 {
    font-family: Visby CF;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    color: #838383;
  }
  > div {
    display: flex;
    justify-content: space-between;
    > div {
      > h1 {
        font-family: Visby CF;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #424242;
      }
      > p {
        font-family: Visby CF;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: #838383;
        margin-bottom: 0;
      }
    }
  }
}
